import { defineStore } from 'pinia'
import type { PaginatorInfoInterface, UserInterface } from '@voix/types'

import { useCookie } from '#imports'

interface UsersAdminInterface {
  currentUser: UserInterface | null
  users: {
    paginatorInfo: PaginatorInfoInterface
    data: Array<UserInterface>
  }
  user: UserInterface | null
}

export const useUserStore = defineStore('userStore', {
  state: () =>
    ({
      currentUserId: null,
      currentUser: null,
      users: { paginatorInfo: { count: 0 }, data: [] },
      user: null,
    } as UsersAdminInterface),

  actions: {
    setUsers(users: {
      paginatorInfo: PaginatorInfoInterface
      data: Array<UserInterface>
    }) {
      this.users = users
    },
    setUser(user: UserInterface) {
      this.user = user
    },

    logout() {
      this.currentUser = null
      const refreshToken = useCookie('auth-refresh-token')
      const accessToken = useCookie('auth-access-token')
      refreshToken.value = null
      accessToken.value = null
    },

  },
})
